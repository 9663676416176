import { ApolloClient, HttpLink, InMemoryCache } from '@apollo/client';

const shopifyEndpoint = `https://store.suitshop.com/api/2024-07/graphql.json`;
const shopifyAccessToken = process.env.GATSBY_SHOPIFY_ACCESS_TOKEN;

export const shopifyClient = new ApolloClient({
    link: new HttpLink({
        uri: shopifyEndpoint,
        headers: {
            'Content-Type': 'application/json',
            'X-Shopify-Storefront-Access-Token': shopifyAccessToken,
        },
    }),
    cache: new InMemoryCache(),
});