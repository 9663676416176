import React, { useState } from "react"
import { ContextProvider } from '~/provider/ContextProvider'
import FitFinderProvider from '~/provider/FitFinderProvider'

import CartDrawer from "./drawer"
import Footer from "./footer"
import TopNavigationBar from "../components/Navigation/TopNavigationBar"
import MainNavigation from "../components/Navigation/MainNavigation"
// import { GlobalStyles } from 'twin.macro'
import "./layout.css"

const Layout = ({ children }) => {
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false)

    return (
        <>
            <a href="#nav" className="absolute -top-[100px] focus:top-0 left-0 bg-rich-black text-white p-3 z-40" tabIndex="0">Skip to navigation</a>

            <a href="#main" className="absolute -top-[100px] focus:top-0 left-0 bg-rich-black text-white p-3 z-40" tabIndex="0">Skip to page content</a>

            <a href="#search" className="absolute -top-[100px] focus:top-0 left-0 bg-rich-black text-white p-3 z-40" tabIndex="0">Skip to search</a>

            <a href="#footer" className="absolute -top-[100px] focus:top-0 left-0 bg-rich-black text-white p-3 z-40" tabIndex="0">Skip to footer</a>

            <ContextProvider>
                <FitFinderProvider>
                    <TopNavigationBar />
                    <MainNavigation open={mobileMenuOpen} setOpen={setMobileMenuOpen} />
                    <CartDrawer />

                    {/* desktop: 104px */}
                    <div id="main" className={`main-content pt-[104px] ${mobileMenuOpen ? `!overflow-hidden` : ``}`} tabIndex="-1">
                        <main style={{ minHeight: '50vh' }}>
                            {children}
                        </main>

                        <Footer />
                    </div>
                </FitFinderProvider>
            </ContextProvider>
        </>
    )
}

export default Layout
